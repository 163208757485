/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React from 'react';
import WorkWithUs from '../containers/WorkWithUsPage';

const WorkWithUsPage = ({ location, pageContext }) => <WorkWithUs pageContext={pageContext} location={location} />;

WorkWithUsPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default WorkWithUsPage;
